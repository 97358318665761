@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik+Scribble&display=swap');
@import 'react-circular-progressbar/dist/styles.css';

:root {
  color-scheme: system;
}

@font-face {
  font-family: 'NHaas Grotesk Regular';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./fonts/final/NHaasGroteskRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'NHaas Grotesk Bold';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/final/NHaasGroteskBold.ttf') format('truetype');
}

@layer base {
  [type='text'],
  input:where(:not([type])),
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply border-gray-300 rounded;
  }
}

body {
  font-family: 'Rubik Scribble', sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@keyframes dialog-overlay-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dialog-overlay-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes dialog-content-show {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes dialog-content-hide {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
}

/* ALIAS CLASSES */

.noramp-bg {
  @apply bg-snow-50 dark:bg-nr-gray-950;
}

.noramp-border {
  @apply border-gray-200 dark:border-gray-800;
}

.noramp-text {
  @apply text-nr-gray-950 dark:text-snow-50;
}

.noramp-sidebar-btn {
  @apply flex w-full p-2.5 rounded-[6px] border-none shadow-none text-[14px];
}

.noramp-sidebar-btn-active {
  @apply text-white;
  letter-spacing: 0.24px;
  background-color: #000;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.08),
    0px 6px 6px 0px rgba(0, 0, 0, 0.07),
    0px 13px 8px 0px rgba(0, 0, 0, 0.04),
    0px 24px 10px 0px rgba(0, 0, 0, 0.01),
    0px 37px 10px 0px rgba(0, 0, 0, 0);
}

.noramp-sidebar-btn-unactive {
  @apply text-[#898989];
}

.app-menu-link {
  @apply flex gap-[10px] items-center;
}

.apexcharts-tooltip {
  @apply dark:!bg-[#313131] dark:!text-white;
}

.apexcharts-tooltip-title {
  @apply dark:!bg-[#313131] dark:!text-white;
}
